import React from 'react';
import { Trans } from '@lingui/macro';

const SignInKligo = () => {
  return (
    <div>
      <h1>
        <Trans>Your account has been successfully created</Trans>
      </h1>
      <p>
        <Trans>
          Your email address and the password you have just created will be used
          to connect to Kligo.
        </Trans>
      </p>
      <h3>
        <Trans>The following steps:</Trans>
      </h3>
      <p>
        <span>1. </span>
        <Trans>Download Kligo:</Trans>{' '}
        <a
          href="https://www.medeo-health.com/ressources/telecharger-kligo"
          rel="noreferrer"
          target="_blank"
        >
          <Trans>here</Trans>
        </a>
      </p>
      <p>
        <span>2. </span>
        <Trans>
          Make an appointment with our engineers to install the software and
          connect your equipment.
        </Trans>
      </p>
      <p>
        <a
          href="https://meetings.hubspot.com/camille-thomas/finaliser-linstallation-de-kligo-pour-votre-cabinet"
          rel="noreferrer"
          target="_blank"
        >
          <Trans>Choose a slot</Trans>{' '}
        </a>
        <Trans>or call us on 0 805 69 22 33</Trans>
      </p>
      <h3>
        <Trans>Thank you for your trust!</Trans>
      </h3>
    </div>
  );
};

export default SignInKligo;
